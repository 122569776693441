const base_url = 'http://localhost/demas_backend/public/api';
// const base_url = "https://demastransport.com/demas_backend/public/api";

export const Constant = {
  basic_token: "Basic YmFzaWM6WW1GemFXTTZhR1ZzY0RFd01TMWhjSEF0Ylc5aWFXeGw=",
  client_id:"demas-app-mobile",
  guest_user: {
    id: "0",
    name: "Guest",
    access_token:
      "Basic YmFzaWM6WW1GemFXTTZhR1ZzY0RFd01TMWhjSEF0Ylc5aWFXeGw=",
    role_id: 2,
    rememberme: false,
    is_loggedin: false,
  },
  google_api_key:"AIzaSyAo9XGd-Ss75Cnfqqu41SdDvlwRu1WYKB0",
  register_or_login: `${base_url}/register_or_login`,
  // sendotp: `${base_url}/sendotp`,
  validate_otp: `${base_url}/validate_otp`,
  user_update_profile: `${base_url}/update`,
};
