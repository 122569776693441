import React, { useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./../styles/Community_people.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import {
  faSliders,
  faUser,
  faSuitcaseRolling,
  faDoorOpen,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Constant } from "../common/Constants";
import { ContextApiContext } from "../context/ContextApi";
import Common, {
  googleTranslate,
  SendRequest,
  SendRequestContetType,
} from "../common/Common";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';



export default function BecomeAgent() {
  const navigate = useNavigate();

  const navigateToPath = (path) => {
    navigate(path);
  };
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: 'Map View', value: '1' },
    { name: 'List View', value: '2' },
  ];

  return (
    <div>
       <Nav_bar_area className="colooo" />
     <Container fluid className="bg_clr_lbluu">
            <Row>
              <Col xs={12} md={12}>
                <div className="top-hd">
                  <h1>Mern - Intern</h1>
                </div>
                <div className="hd-1">
                <a href="Gaditek">Gaditek</a>
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStarHalfStroke} />
                  <h6>18 reviews </h6>
                  <h4>Karachi</h4>
                </div>
                <div className="md-5">
                <Button variant="primary" className="but">Apply Now</Button>{' '}
                  <Button variant="primary" className="btt"><FontAwesomeIcon icon={faBookmark} /></Button>{' '}
                </div>
                <div className="fsf">
                <h1> Job Details</h1>
                  <h5>Here’s how the job details align with your job preferences.</h5>
                  <h5> Manage job preferences anytime in your profile.</h5>
                  <h3><span><FontAwesomeIcon icon={faBriefcase} /></span>Job Type</h3>
                  <Button variant="primary" className="ft">Full-time</Button>{' '}
                  <Button variant="primary" className="ft">Internship</Button>{' '}
                  <h4>
                    This is a full-time on-site Mern - Internship located in Karāchi. As a Mern - Intern, you will work closely with our experienced developers
                    on a challenging and diverse range of projects within the technology industry. You will gain hands-on experience in building dynamic
                    web applications and work on multiple projects. You will build applications using the MERN stack, MongoDB, Express.js, React.js, and
                    Node.js.
                  </h4>
                </div>
                <div className="ql_fc">
                  <h2>Qualifications</h2>
                  <ul>
                    <li>Proficient in JavaScript, CSS, HTML</li>
                    <li>Familiarity with React.js, Redux and its core principles, Node.js, and Express.js</li>
                    <li>Familiarity with RESTful APIs</li>
                    <li>Understanding of MongoDb and NoSQL database, including performance tuning and scaling</li>
                    <li>Understanding of data structures, algorithms, and system design</li>
                    <li>Knowledge of Git, Github, and code versioning tools</li>
                    <li>Strong attention to detail, analytical thinking, and problem-solving skills</li>
                    <li>Excellent communication and interpersonal skills</li>
                    <li>Currently pursuing a Bachelor's or higher degree in Computer Science, Software Engineering or arelated field</li>
                  </ul>
                  <h4>Job Types: Full-time, Internship</h4>
                  <h4>Ability to commute/relocate:</h4>
                  <div className="qli_fc">
                  <ul>
                    <li>Karachi: Reliably commute or planning to relocate before starting work (Required).</li>
                  </ul>
                  </div>
                </div>
                <Row>
                  <Col>
                    {/* <div className="sec">
                      <h1>Filter Services:</h1>
                      <div className="flxs">
                        <InputGroup className="mb-3">
                          <InputGroup.Text>Keyword Search:</InputGroup.Text>
                          <Form.Control aria-label="Amount (to the nearest dollar)" className="inp"/>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div> */}
                    <div className="btn-toggle">
                      <>
                        <ButtonGroup className="mb-2">
                          {radios.map((radio, idx) => (
                            <ToggleButton
                              key={idx}
                              id={`radio-${idx}`}
                              type="radio"
                              variant="toogle"
                              name="radio"
                              value={radio.value}
                              checked={radioValue === radio.value}
                              onChange={(e) => setRadioValue(e.currentTarget.value)}
                            >
                              {radio.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                        <br />
                      </>
                      <h5>6 services found</h5>
                    </div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184551.90977292243!2d-79.54286398851995!3d43.71837095798251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2s!4v1702975873597!5m2!1sen!2s"
                      width="100%"
                      height="450"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container fluid className="bg_dblu">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <img
                className="logo_ft"
                src="/images/slicing/Help101-Web.png"
                alt="Content"
              />
            </Col>
            <Col xs={12} md={9}>
              <Row>
                <Col xs={12} md={12} className="ser_area">
                  <InputGroup className="mb-3 ft_mb3">
                    <Form.Control
                      placeholder="Your E-mail"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className="ft_inp"
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="ft_inp_btn addon2ft"
                    >
                      Subscribe Now!
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-3">
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Partnership</h5>
                    <li>Websites</li>
                    <li>Social Media</li>
                    <li>Branding</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>About</h5>
                    <li>Our Projects</li>
                    <li>Careers</li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <ul className="list_ft">
                    <h5>Support</h5>
                    <li>Support Request</li>
                    <li>Contact</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col>
            <div className="socialicon">
              <img src="/images/slicing/instagram-logo.png" />
              <img src="/images/slicing/3536424.png" />
              <img src="/images/slicing/124013.png" />
              <img src="/images/slicing/3536394.png" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <div className="ft-hr">
            <h4>Copyright © 2023-2028 Help101.ca. All Rights Reserved.</h4>
          </div>
        </Row>
      </Container>
    </div>
  );
}
