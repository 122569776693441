import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import DropdownButton from 'react-bootstrap/DropdownButton';

import {
  faHouse,
  faUser,
  faRightToBracket,
  faPhoneVolume,
  faBarsProgress,
  faShieldHalved,
  faFileCircleQuestion,
  faCommentsDollar,
  faBookBookmark,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";


export default function Nav_bar_area() {
  const user = localStorage.getItem("user");
  let user_loggedin = false;
  if (user == null) {
    user_loggedin = false;
  } else {
    user_loggedin = user.is_loggedin;
  }

  const navigate = useNavigate();

  const navigateToPath = (path) => {
    navigate(path);
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      {/* {[false, 'sm', 'lg', 'lg', 'xl', 'xxl'].map((expand) => ( */}
      <Navbar key="xl" expand="lg" className="bg-body-tertiary" variant="dark">
        <Container fluid className="nav_back">
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Brand className="brand_tx" href="#">
            <img className="nav_im" src="./images/slicing/logo.png" />
          </Navbar.Brand>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <div className="logo_menu_box">
                      <img src="./images/slicing/logo.png" alt="" class="img-responsive" />
                </div>
                <div></div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                  <div class="topnav">
                    <a class="active" href="#home">Home</a>
                    <a href="#news">Showcase</a>
                    <a href="#contact">Contact</a>
                    <a href="#services">Services</a>
                    <a href="#our products">Our Products</a>
                    <a href="#solutions">Solutions</a>
                    <a href="#domain & hosting">Domain & Hosting</a>
                  </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
}
