import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ErrorAlert from "./js/Components/ErrorAlert";
import Home from "./js/Home";
import Community_people from "./js/Community_people";
import ContexApiProvider from "./context/ContextApi";
import LoginModal from "./js/Components/LoginModal";
import Services_payment from "./js/Services_payment";

function App() {
  let user = localStorage.getItem("user");
  let user_loggedin = false;
  if (user == null) {
    user_loggedin = false;
  } else {
    user = JSON.parse(user);
    user_loggedin = user.is_loggedin;
  }

 
  return (
    <div className="App">
      <ContexApiProvider>
        <Router>
          <ErrorAlert />
          <LoginModal />
          <Routes>
            <Route Component={Home} path="/"></Route>
            <Route
              Component={Community_people}
              path="/community_people"
            ></Route>
            <Route
              Component={Services_payment}
              path="/services_payment"
            ></Route>
          </Routes>
        </Router>
      </ContexApiProvider>
    </div>
  );
}

export default App;
