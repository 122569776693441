import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import "./../styles/home.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons";
import { faKitMedical } from "@fortawesome/free-solid-svg-icons";
import { faSuitcaseMedical } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate } from "react-router-dom";

import { ContextApiContext } from "../context/ContextApi";
import { Alert } from "react-bootstrap";
import { Constant } from "../common/Constants";
import { SendRequest } from "../common/Common";
import Offcanvas from "react-bootstrap/Offcanvas";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function Home_Page() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <section className="bg-cl">
        <Container fluid>
          <Row>
            <Col xs={12} md={3} className="lg_area">
              <div class="topnav_logo">
                <img
                  src="/images/slicing/logo.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </Col>
            <Col xs={12} md={9}>
              <div class="topnav">
                <a class="active" href="/">
                  Home
                </a>
                <a class="lnk" href="#showcase">
                  Showcase
                </a>
                <a class="lnk" href="#contact">
                  Contact
                </a>
                <a class="lnk" href="#services">
                  Services
                </a>
                <a class="lnk" href="#our_products">
                  Our Products
                </a>
                <a class="lnk" href="#solutions">
                  Solutions
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sec-bg">
        <Container fluid>
          <Row>
            <Col xs={1} md={2}></Col>
            <Col xs={10} md={7}>
              <div className="nv-bt">
                <Button variant="primary" onClick={handleShow} className="nav">
                  <FontAwesomeIcon icon={faBars} />
                </Button>
                <img
                  src="/images/slicing/logo.png"
                  alt=""
                  class="img-responsive asd"
                />
              </div>
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className="tp-nv">
                  <Offcanvas.Title>
                    <img
                      src="/images/slicing/logo.png"
                      alt=""
                      class="img-responsive"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="off">
                  <div class="topnav">
                    <a class="active" href="/">
                      Home
                    </a>
                    <a href="#showcase">Showcase</a>
                    <a href="#contact">Contact</a>
                    <a href="#services">Services</a>
                    <a href="#our_products">Our Products</a>
                    <a href="#solutions">Solutions</a>
                    <a href="#contact">Domain & Hosting</a>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
              <div class="tophd">
                <h3>Become A Locum Doctor In The UK</h3>
                <h1>Our Agency Provide Best Locum Doctors / Works</h1>
                <h5>
                  We are a team of over 100 experienced locum recruitment
                  specialists, each with over a decade of expertise in providing
                  exceptional services.
                </h5>
              </div>
            </Col>
            <Col xs={1} md={3}></Col>
          </Row>
          <div class="field">
            <div class="scroll"></div>
          </div>
        </Container>
      </section>
      <section id="showcase" className="third-sec">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <div className="hd-one">
                <h5>ABOUT COMPANY</h5>
                <h1>
                  <span>You Can n</span>ot Use Up Creativity.
                </h1>
                <p className="para">
                  {" "}
                  We specialise in temporary and permanent recruitment within
                  the medical, healthcare and education sectors. We are
                  preferred suppliers to a large number of our clients and offer
                  access to an impressive pool of highly trained and skilled
                  healthcare and teaching professionals, nationwide.
                </p>
              </div>
              <div className="hd-two">
                <img
                  src="/images/slicing/16.png"
                  alt=""
                  class="img-responsive"
                />
                <h4>
                  Experience
                  <span>Our great team of more than 1400 medical experts.</span>
                </h4>
              </div>
              <div className="hd-two">
                <img
                  src="/images/slicing/17.png"
                  alt=""
                  class="img-responsive"
                />
                <h4>
                  Quick Support
                  <span>
                    We’ll help you get to opportunities fast and secure.
                  </span>
                </h4>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="jeni">
                <img
                  src="/images/slicing/15.png"
                  alt=""
                  class="img-responsive"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="services" className="forth-sec">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="bx-comm">
                <h1>100 +</h1>
                <h2>Valid opportunities</h2>
                <h6>
                  {" "}
                  we have successfully executed over many locum doctor
                  recruitment projects.
                </h6>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="bx-comm">
                <h1>2k</h1>
                <h2>Happy Clients</h2>
                <h6>
                  We have collaborated with over 2,000 satisfied and contented
                  healthcare professionals.
                </h6>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="bx-hd">
                <h5>Who We Are</h5>
                <h1>
                  We specialize in providing top-notch recruitment services
                  tailored specifically for locum doctors.
                </h1>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img
                  src="/images/slicing/12.png"
                  alt=""
                  class="img-responsive"
                />
                <h2>Medical Solutions</h2>
                <h6>
                  Our team manages every facet of your locum doctor recruitment
                  needs.
                </h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img
                  src="/images/slicing/13.png"
                  alt=""
                  class="img-responsive"
                />

                <h2>Confidentiality Assurance</h2>
                <h6>
                  We prioritize your privacy. Entrust your locum doctor
                  recruitment projects to our team with complete
                  confidentiality.
                </h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img
                  src="/images/slicing/14.png"
                  alt=""
                  class="img-responsive"
                />
                <h2>Recruitment Platform</h2>
                <h6>
                  Utilizing cutting-edge frameworks and tools, we craft and
                  construct your recruitment platform.
                </h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img
                  src="/images/slicing/11.png"
                  alt=""
                  class="img-responsive"
                />

                <h2>Secure Database</h2>
                <h6>
                  Our procedures include safeguarding and monitoring customer
                  databases with established backup and recovery protocols.
                </h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="our_products" className="fivth-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="fv-hd">
                <h1>
                  We've successfully managed over 100+ locum doctor recruitment
                  projects to completion.
                </h1>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-bx">
                <img
                  src="/images/slicing/10.png"
                  alt=""
                  class="img-responsive"
                />
                <h1>107</h1>
                <h5>Completed Project</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b1">
                <img
                  src="/images/slicing/9.png"
                  alt=""
                  class="img-responsive"
                />
                <h1>2k</h1>
                <h5>Happy Clients</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b2">
                <img
                  src="/images/slicing/8.png"
                  alt=""
                  class="img-responsive"
                />
                <h1>71</h1>
                <h5>Multi Service</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b3">
                <img
                  src="/images/slicing/7.png"
                  alt=""
                  class="img-responsive"
                />
                <h1>19</h1>
                <h5>Winning Awards</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="solutions" className="six-sec">
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <div className="sx-tec">
                <h4>LOCUM RECRUITMENT INDEX</h4>
                <h1>
                  We Provide Opportunities with the Aim of Establishing
                  Trustworthy Partnerships
                </h1>
              </div>
            </Col>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={2}>
              <div className="ap-pic sm_ht">
                {/* <img
      src="/images/slicing/3.png"
      alt=""
      className="img-responsive"
    /> */}
                <FontAwesomeIcon icon={faStethoscope} />
                <h6>General Practice</h6>
              </div>
              <div className="ap-pics sm_ht">
                <img
                  src="/images/slicing/4.png"
                  alt=""
                  className="img-responsive"
                />
                <h6>Specialist Practice</h6>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="ap-imgg sm_ht">
                {/* <img
      src="/images/slicing/2.png"
      alt=""
      className="img-responsive"
    /> */}
                <FontAwesomeIcon icon={faKitMedical} />
                <h6>Emergency Medicine</h6>
              </div>
              <div className="ap-img sm_ht">
                <img
                  src="/images/slicing/5.png"
                  alt=""
                  className="img-responsive"
                />

                <h6>Consultant</h6>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="ap-imge sm_ht">
                {/* <img
      src="/images/slicing/1.png"
      alt=""
      className="img-responsive"
    /> */}
                <FontAwesomeIcon icon={faSuitcaseMedical} />
                <h6>Medical Specialist</h6>
              </div>
              <div className="ap-imges sm_ht">
                <img
                  src="/images/slicing/6.png"
                  alt=""
                  className="img-responsive"
                />
                <h6>Locum Tenens</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact" className="contact-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="cont">
                <h1>CONTACT US</h1>
                <h5>
                  Feel free to reach out with any inquiries or for an estimate
                  by filling out the form below.{" "}
                </h5>
                <h6>We're here to help!</h6>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Control type="name" placeholder="First Name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Control type="phone" placeholder="Phone No" />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Control type="name" placeholder="Last Name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} md={12}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea3"
                >
                  <Form.Label className="comm-box">
                    Comment or Message
                  </Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group>
                <Button variant="primary" className="cont-bt">
                  SEND MESSAGE
                </Button>{" "}
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-sec">
        <Container fluid>
          <Row>
            <Col md={1}></Col>
            <Col xs={12} md={3}>
              <div className="ft-txt">
                <h4>LOCAL-LOCUM</h4>
                <h6>
                  We are a team of over 100 seasoned locum recruitment
                  specialists, each boasting over a decade of experience in
                  matching top-tier professionals with exceptional
                  opportunities.
                  {/* We are a team of over 100 seasoned locum recruitment
                  specialists, each boasting over a decade of experience in
                  matching top-tier professionals with exceptional
                  opportunities. */}
                </h6>
                <div className="ft-text">
                  <h4>CONTACT US</h4>
                  <h6>
                    Call: +92 21 1234567 Email: support@local-locum.com Address:
                    123, Street No. Location.
                  </h6>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="ft-txt">
                <h4>MENU</h4>
                <h6>Home | About | Project | Services | Contact us</h6>
                <h4>LEGAL</h4>
                <h6>Terms of Use & Privacy</h6>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="ft-lt">
                <h4>NEWSLETTER</h4>
                <h6>
                  Keep yourself updated about the Changes LOCAL-LOCUM . Get
                  Subscribed!
                </h6>
                <InputGroup className="mb-1">
                  <Form.Control
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="ft_bt"
                  />
                  <InputGroup.Text id="basic-addon1">SUBMIT</InputGroup.Text>
                </InputGroup>
                <div className="foot_lgo">
                  <img
                    src="/images/slicing/logo.png"
                    alt=""
                    class="img-responsive black_bg"
                  />
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </section>
      <section className="lab-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="ft-l">
                <h4>
                  Copyright © 2024 local-locum.com | All Rights Reserved |
                  Designed by Jeni Soft Technologies
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
